import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
// import rectora from "../../assets/rectora.jpg";
import gobernador from "../assets/Gobernador.jpg"
import "./SecondPage.css"
// import { getBooks } from '../../services/bookServices';
import { useDispatch } from 'react-redux';
// import { booksCopyAction } from '../../stateManagement/actions/booksInfoAction';
// import { booksCatalogoAction, booksCopyAction, booksInfo } from '../../stateManagement/actions/booksInfoAction';
// import { getAuthors, getCountries, getGenres } from '../../services/dataByLanguage';
// import { booksAuthors, booksCountries, booksGenres } from '../../stateManagement/actions/stateActions';
// import { recomendedAction } from '../../stateManagement/actions/recommendedAction';
// import { changeLanguageAction } from '../../stateManagement/actions/changeLanguageAction';



export default function SecondPage() {

  const dispatch = useDispatch()

//   const demo = true

//     const charge = async () =>{
//         dispatch(changeLanguageAction(1))
//         // const [genres, countries, authors] = await Promise.all([
//         //     getGenres(1),
//         //     getCountries(1),
//         //     getAuthors(1),
//         // ]
//         // )
//         const books = await getBooks(1)
//         if(books?.length>0){
//         // const recommend = books.filter(r => r.isFree===true)
//         // dispatch(recomendedAction(recommend))
//         if(demo){
//             books.sort((x, y) => x.name.localeCompare(y.name))
//             const demoBooks = books.slice(0,100)
//             // dispatch(booksInfo(demoBooks))
//             // dispatch(booksCatalogoAction(demoBooks))
//             dispatch(booksCopyAction(demoBooks))
//             // dispatch(recomendedAction(recommend))
//         }
//         else {
//             // dispatch(booksInfo(books))
//             // dispatch(booksCatalogoAction(books))
//             dispatch(booksCopyAction(books))
//             // dispatch(recomendedAction(recommend))
//         }
//         }
//         // dispatch(booksGenres(genres))
//         // dispatch(booksCountries(countries))
//         // dispatch(booksAuthors(authors))
//     }

//     useEffect(()=> {// eslint-disable-next-line react-hooks/exhaustive-deps
//     charge();}, [])



    return (
        <div className="container_pg">
        <br/>
        <div>
            <h4 id="titulo1_"> <b><i>  CENTRO EDUCATIVO CATALINA <br />
                </i></b></h4>

                <img
                        src={gobernador}
                        width="180"
                        height="180"
                        className="img-fluid d-block mx-auto"
                        alt='logoMingga'
                        />
                        


            <h5 id="titulo1_" > <b><i> HENRY ROMERO TRUJILLO
            <br />
            Director</i></b> </h5>
            <br />


            <h6 id="titulo1_" className="container_form"> La lectura es uno de los hábitos más importantes para las personas: es la puerta de acceso al conocimiento en las diversas áreas, y al patrimonio literario nacional y universal. Permite mejorar el nivel educativo y cultural de la sociedad, y fortalece las competencias comunicativas, claves para tener éxito en la moderna sociedad de la información y las comunicaciones integrada a nivel orbital.
                <br />
                <br />
                Por eso hemos diseñado el Plan Nacional de Lectura “LEO LA VIDA”, con más de 15.000 obras gratuitas en 5 idiomas, para mejorar los indicadores de lectura, con la meta de que los colombianos leamos 1 libro al mes, 12 libros al año.
            </h6>
            <br />
            
            <h5 id="titulo1_" > <b><i>
            Vamos a leer <br />
            Para construir entre todos <br />
            un mundo donde los nniños y niñas sean más educados y cultos. <br />
            Colombia, un país de lectores</i></b> </h5>
            <br />


            
            <br />
            <Link         
            className="btn btn-success" id="seguir_1" to="/pageFilters"><b>Seguir </b> 
            </Link>
            <br />
            

        </div>
        </div>
    )
}